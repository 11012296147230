import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'

import bg from 'vue2-datepicker/locale/es/bg'
import es from 'vue2-datepicker/locale/es/es'
import ru from 'vue2-datepicker/locale/es/ru'
import uk from 'vue2-datepicker/locale/es/uk'
import en from 'vue2-datepicker/locale/es/en'

export default {
  data() {
    return {
      firstDayOfWeek: DateTime.now().setLocale(navigator.language).startOf('week').weekday % 7,
      rangeSeparator: ' – ',
    }
  },
  computed: {
    ...mapGetters({
      locale: 'main/locale',
    }),
    datePickerLang() {
      let datepickerLocale = null

      if (this.locale === 'bg') {
        datepickerLocale = bg
      } else if (this.locale === 'es') {
        datepickerLocale = es
      } else if (this.locale === 'ru') {
        datepickerLocale = ru
      } else if (this.locale === 'uk') {
        datepickerLocale = uk
      } else {
        datepickerLocale = en
      }

      return {
        ...datepickerLocale,
        formatLocale: {
          ...datepickerLocale.formatLocale,
          firstDayOfWeek: this.firstDayOfWeek,
        },
      }
    },
  },
}
