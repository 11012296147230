<template>
  <app-collapse-item title="">
    <template v-slot:header>
      <div class="d-flex align-items-center justify-content-between w-100">
        <div class="line-height-1 mr-1">
          {{ title }}
        </div>
        <feather-icon
          icon="TrashIcon"
          role="button"
          size="16"
          class="flex-shrink-0"
          style="color: #ea5455"
          @click.stop="$emit('delete', schedule.id)"
        />
      </div>
    </template>
    <b-form-radio
      v-for="(repeatItem, repeatItemIndex) in repeat"
      :key="repeatItem.value"
      v-model="schedule.repeat"
      :value="repeatItem.value"
      :class="[repeatItemIndex + 1 === repeat.length ? null : 'mb-50']"
      @change="onChangeRepeat(repeatItem.value)"
    >
      {{ repeatItem.name }}
    </b-form-radio>
    <div
      v-if="schedule.repeat === 'week'"
      class="mt-1 mb-n25"
    >
      <b-badge
        v-for="(weekDay, weekDayIndex) in weekDays"
        :key="weekDay.value"
        role="button"
        :class="['mb-25', weekDayIndex + 1 === weekDays.length ? null : 'mr-50']"
        :variant="schedule.days.find((i) => i === weekDay.value)? 'primary' : 'light-primary'"
        @click="onClickDay(weekDay.value)"
      >
        {{ weekDay.name }}
      </b-badge>
    </div>
    <date-picker
      v-model="datePickerModel"
      :range="true"
      :type="schedule.repeat === 'no' ? 'datetime' : 'time'"
      :formatter="datePickerFormatter"
      :clearable="false"
      :editable="false"
      :lang="datePickerLang"
      prefix-class="xmx"
      :range-separator="rangeSeparator"
      :placeholder="datePickerPlaceholder"
      class="mt-1"
      :time-picker-options="{
        format: isTimeFormat12 ? 'hh:mm A' : 'HH:mm',
        start: '00:00',
        step: '00:15',
        end: '23:45',
      }"
    />
  </app-collapse-item>
</template>

<script>
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { BFormRadio, BBadge } from 'bootstrap-vue'
import { DateTime, Info } from 'luxon'
import { mapGetters, mapActions } from 'vuex'
import mixinRequests from '@/mixins/requests'
import mixinDatepicker from '@/mixins/datepicker'
import DatePicker from 'vue2-datepicker'

import UPDATE_SCHEDULE from '@/gql/mutation/schedule/updateSchedule.gql'

export default {
  name: 'Schedule',
  components: {
    BFormRadio,
    BBadge,
    DatePicker,
    AppCollapseItem,
  },
  mixins: [
    mixinRequests,
    mixinDatepicker,
  ],
  props: {
    scheduleFetched: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      isTimeFormat12: DateTime.now().setLocale(navigator.language).toLocaleString(DateTime.TIME_SIMPLE).includes('AM')
        || DateTime.now().toLocaleString(DateTime.TIME_SIMPLE).includes('PM'),
      schedule: null,
    }
  },
  computed: {
    ...mapGetters({
      locale: 'main/locale',
      schedules: 'schedules/schedules',
    }),
    repeat() {
      return [
        {
          name: this.$t('scheduleRepeatNo'),
          value: 'no',
        },
        {
          name: this.$t('scheduleRepeatDay'),
          value: 'day',
        },
        {
          name: this.$t('scheduleRepeatWeek'),
          value: 'week',
        },
      ]
    },
    weekDays() {
      const weekDays = Info
        .weekdays('short', { locale: 'en-US' })
        .map((i, iIndex) => ({
          index: iIndex + 1,
          value: i.toLocaleLowerCase(),
          name: Info.weekdays('short', { locale: this.locale })[iIndex],
        }))
      const weekDaysFirstDayIndex = weekDays.findIndex(i => i.index === this.firstDayOfWeek)
      const weekDaysDeleted = weekDays.splice(weekDaysFirstDayIndex, weekDays.length)

      return [...weekDaysDeleted, ...weekDays]
    },
    datePickerPlaceholder() {
      const separator = this.rangeSeparator
      return this.schedule.repeat === 'no'
        ? `${this.$t('scheduleDateTimeFromPlaceholder')}${separator}${this.$t('scheduleDateTimeToPlaceholder')}`
        : `${this.$t('scheduleTimeFromPlaceholder')}${separator}${this.$t('scheduleTimeToPlaceholder')}`
    },
    datePickerFormatter() {
      return {
        stringify: date => (date
          ? DateTime
            .fromJSDate(date)
            .setLocale(navigator.language)
            .toLocaleString(this.schedule.repeat === 'no' ? DateTime.DATETIME_SHORT : DateTime.TIME_SIMPLE)
          : ''
        ),
        parse: value => (value ? new Date(value) : null),
      }
    },
    datePickerModel: {
      get() {
        if (this.schedule.repeat === 'no') {
          const fromDate = DateTime.fromISO(this.schedule.fromDate)
          const toDate = DateTime.fromISO(this.schedule.toDate)
          return [
            fromDate.isValid ? fromDate.toJSDate() : null,
            toDate.isValid ? toDate.toJSDate() : null,
          ]
        }

        let result = [null, null]
        if (this.schedule.fromTime) {
          const date = DateTime.now().startOf('day').toJSDate()
          const [hours, minutes] = this.schedule.fromTime.split(':')
          date.setHours(+hours)
          date.setMinutes(+minutes)
          result = [date, result[1]]
        }
        if (this.schedule.toTime) {
          const date = DateTime.now().startOf('day').plus({ days: 1 }).toJSDate()
          const [hours, minutes] = this.schedule.toTime.split(':')
          date.setHours(+hours)
          date.setMinutes(+minutes)
          result = [result[0], date]
        }
        return result
      },
      set(value) {
        if (this.schedule.repeat === 'no') {
          if (value[0]) {
            const val = value[0]
            this.schedule.fromDate = DateTime.fromJSDate(val).toISO()
          }
          if (value[1]) {
            const val = value[1]
            this.schedule.toDate = DateTime.fromJSDate(val).toISO()
          }
        } else {
          if (value[0]) {
            const val = value[0]
            this.schedule.fromTime = `${val.getHours()}:${val.getMinutes() < 10 ? '0' : ''}${val.getMinutes()}`
          }
          if (value[1]) {
            const val = value[1]
            this.schedule.toTime = `${val.getHours()}:${val.getMinutes() < 10 ? '0' : ''}${val.getMinutes()}`
          }
        }

        const patchDateTime = this.patchDateTime.bind(this)
        this.requests.push(patchDateTime, () => [this.getMenusState()])
      },
    },
    rangeHumanReadable() {
      if (this.schedule.repeat === 'no') {
        if (!this.schedule.fromDate || !this.schedule.toDate) {
          return null
        }

        const now = DateTime.now()
        const fromDate = DateTime.fromISO(this.schedule.fromDate)
        const toDate = DateTime.fromISO(this.schedule.toDate)
        const fromRelativeDays = fromDate.diff(now, 'days').as('days')
        const toRelativeDays = toDate.diff(now, 'days').as('days')

        const from = fromRelativeDays > -2 && fromRelativeDays < 1
          ? `${fromDate.toRelativeCalendar()} ${fromDate.setLocale(this.locale).toLocaleString(DateTime.TIME_SIMPLE)}`
          : fromDate.setLocale(this.locale).toLocaleString(DateTime.DATETIME_MED)

        // eslint-disable-next-line no-nested-ternary
        const to = fromDate.hasSame(toDate, 'day')
          ? toDate.setLocale(this.locale).toLocaleString(DateTime.TIME_SIMPLE)
          : toRelativeDays > -1 && toRelativeDays < 1
            ? `${toDate.toRelativeCalendar()} ${toDate.setLocale(this.locale).toLocaleString(DateTime.TIME_SIMPLE)}`
            : toDate.setLocale(this.locale).toLocaleString(DateTime.DATETIME_MED)

        return `${from}${this.rangeSeparator}${to}`
      }

      if (!this.schedule.fromTime || !this.schedule.toTime) {
        return null
      }

      const timeRange = `${this.schedule.fromTime}${this.rangeSeparator}${this.schedule.toTime}`

      if (this.schedule.days.length) {
        const days = this.weekDays.filter(i => this.schedule.days.find(j => j === i.value)).map(i => i.name).join(' ')
        return `${this.$t('scheduleRepeatOutputEvery')} ${days} ${timeRange}`
      }

      return `${this.$t('scheduleRepeatOutputEveryDay')} ${timeRange}`
    },
    title() {
      return this.rangeHumanReadable || this.$t('scheduleNoRange')
    },
  },
  watch: {
    scheduleFetched: {
      handler(newValue) {
        this.schedule = {
          ...newValue,
          // eslint-disable-next-line no-nested-ternary
          repeat: newValue.repeat === false ? 'no' : !newValue.days.length ? 'day' : 'week',
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      setSchedules: 'schedules/setSchedules',
      getMenusState: 'menuManagement/getMenusState',
    }),
    patchSchedule(payload = { param1: 'value1', param2: 'value2' }) {
      this.setSchedules(this.schedules.map(i => (i.id === this.schedule.id ? { ...i, ...payload } : i)))

      return [
        this.$apollo.mutate({
          mutation: UPDATE_SCHEDULE,
          variables: {
            updateScheduleId: this.schedule.id,
            ...payload,
          },
        }),
      ]
    },

    patchRepeatDays() {
      return this.patchSchedule({
        repeat: this.schedule.repeat !== 'no',
        days: this.schedule.days,
      })
    },
    onChangeRepeat(value) {
      if (value === 'day') {
        this.schedule = {
          ...this.schedule,
          days: [],
        }
      }

      if (value === 'week') {
        this.schedule = {
          ...this.schedule,
          days: this.schedule.days.length
            ? [...this.schedule.days]
            : [
              this.weekDays[0].value,
              this.weekDays[1].value,
              this.weekDays[4].value,
              this.weekDays[5].value,
            ], // random, min length 1
        }
      }

      const patchRepeatDays = this.patchRepeatDays.bind(this)
      this.requests.push(patchRepeatDays, () => [this.getMenusState()])
    },
    onClickDay(value) {
      if (this.schedule.days.length === 1 && this.schedule.days[this.schedule.days.length - 1] === value) {
        return
      }

      this.schedule = {
        ...this.schedule,
        days: this.schedule.days.find(j => j === value)
          ? this.schedule.days.filter(j => j !== value)
          : [...this.schedule.days, value],
      }

      const patchRepeatDays = this.patchRepeatDays.bind(this)
      this.requests.push(patchRepeatDays, () => [this.getMenusState()])
    },

    patchDateTime() {
      return this.patchSchedule({
        fromDate: this.schedule.fromDate,
        toDate: this.schedule.toDate,
        fromTime: this.schedule.fromTime,
        toTime: this.schedule.toTime,
      })
    },
  },
}
</script>

<style lang="sass">
  @import '@/assets/sass/vue2-datepicker'
</style>
